import React from "react"
import loadable from '@loadable/component';

const Error404app = loadable(() => import('../components/error404app'))
const Head = loadable(() => import('../components/Head'))


const ErrorPage = () => {
    
  return (    
      <>
          <Head title="Page Not Found" />
          <Error404app/>          
      </>    
  );
};

export default ErrorPage;
